<template>
  <NuxtLink :to="link" class="wax-intro-link">
    <div class="wax-intro-link-content">
      <Headline level="3" :size="4" class="h4" no-margin>
        {{ title }}
      </Headline>
      <span class="wax-intro-link-category">
        {{ category }}
      </span>
    </div>

    <picture class="wax-intro-link-picture">
      <source
        v-for="imgType in createFormatArray(srcset)"
        :key="imgType.type"
        :type="imgType.type"
        :srcset="imgType.srcsetstring"
        sizes="120px"
      />
      <img
        class="wax-intro-link-image"
        :src="image"
        :alt="altText"
        loading="lazy"
      />
    </picture>
  </NuxtLink>
</template>

<script>
  import { AlchemyElement } from "~/mixins/alchemyVue"
  import createFormatArray from "~/mixins/createFormatArray"

  export default {
    mixins: [AlchemyElement, createFormatArray],
    computed: {
      title() {
        return this.getValue("title")
      },
      category() {
        return this.getValue("category")
      },
      imageIngredient() {
        return this.getIngredient("image") || {}
      },
      image() {
        return this.imageIngredient.value
      },
      srcset() {
        return this.imageIngredient.srcset
      },
      link() {
        return this.getValue("link")
      },
      altText() {
        return this.imageIngredient.altText
      },
    },
  }
</script>

<style lang="scss" scoped>
  .wax-intro-link {
    display: flex;
    grid-gap: $space-s;
    justify-content: space-between;
    color: inherit;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
      .wax-intro-link-image {
        transform: scale(1.05) translate3d(0, 0, 0) perspective(1px);
        box-shadow: $hover-shadow;
      }
    }
  }
  .wax-intro-link-picture {
    display: flex;
  }
  .wax-intro-link-image {
    width: 120px;
    height: 120px;
    transition:
      transform 120ms ease-in-out,
      box-shadow 120ms ease-in-out;
    transform-origin: 50% 50%;
    will-change: transform, box-shadow;
    object-fit: cover;
    box-shadow: $shadow;
    border-radius: $border-radius;
  }
  .wax-intro-link-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .wax-intro-link-category {
    color: $red-text;
    font-weight: bold;
  }
</style>
